import React, { useEffect, useState } from 'react';
import GLightbox from 'glightbox'; // Import GLightbox
import Skeleton from '@mui/material/Skeleton'; // Import Skeleton

//When adding for college, do it here.
import batch1_1 from '../assets/testimonials/College/Batch_1/batch1-1.jpg';
import batch1_2 from '../assets/testimonials/College/Batch_1/batch1-2.jpg';
import batch1_3 from '../assets/testimonials/College/Batch_1/batch1-3.jpg';
import batch1_4 from '../assets/testimonials/College/Batch_1/batch1-4.jpg';
import batch1_5 from '../assets/testimonials/College/Batch_1/batch1-5.jpg';
import batch1_6 from '../assets/testimonials/College/Batch_1/batch1-6.jpg';
import batch1_7 from '../assets/testimonials/College/Batch_1/batch1-7.jpg';
import batch2_1 from '../assets/testimonials/College/Batch_2/batch2-1.png';
import batch2_2 from '../assets/testimonials/College/Batch_2/batch2-2.png';
import batch2_3 from '../assets/testimonials/College/Batch_2/batch2-3.png';
import batch2_4 from '../assets/testimonials/College/Batch_2/batch2-4.png';
import batch2_5 from '../assets/testimonials/College/Batch_2/batch2-5.png';
import batch2_6 from '../assets/testimonials/College/Batch_2/batch2-6.png';
import batch2_7 from '../assets/testimonials/College/Batch_2/batch2-7.png';
import batch2_8 from '../assets/testimonials/College/Batch_2/batch2-8.png';
import batch2_9 from '../assets/testimonials/College/Batch_2/batch2-9.png';
import batch2_10 from '../assets/testimonials/College/Batch_2/batch2-10.png';
import batch2_11 from '../assets/testimonials/College/Batch_2/batch2-11.png';
import batch3_11 from '../assets/testimonials/College/Batch_3/batch3-11.jpg';
import batch3_1 from '../assets/testimonials/College/Batch_3/batch3-1.jpg';
import batch3_2 from '../assets/testimonials/College/Batch_3/batch3-2.jpg';
import batch3_3 from '../assets/testimonials/College/Batch_3/batch3-3.jpg';
import batch3_4 from '../assets/testimonials/College/Batch_3/batch3-4.jpg';
import batch3_5 from '../assets/testimonials/College/Batch_3/batch3-5.jpg';
import batch3_6 from '../assets/testimonials/College/Batch_3/batch3-6.jpg';
import batch3_7 from '../assets/testimonials/College/Batch_3/batch3-7.png';
//import batch3_8 from '../assets/testimonials/batch3-8.png';
import batch3_9 from '../assets/testimonials/College/Batch_3/batch3-9.jpg';
import batch3_10 from '../assets/testimonials/College/Batch_3/batch3-10.png';
import batch4_1 from '../assets/testimonials/College/Batch_4/batch4-1.jpg';
import batch4_2 from '../assets/testimonials/College/Batch_4/batch4-2.jpg';
import batch4_3 from '../assets/testimonials/College/Batch_4/batch4-3.jpg';
import batch4_4 from '../assets/testimonials/College/Batch_4/batch4-4.jpg';
import batch4_5 from '../assets/testimonials/College/Batch_4/batch4-5.jpg';
import batch4_6 from '../assets/testimonials/College/Batch_4/batch4-6.jpg';
import batch4_7 from '../assets/testimonials/College/Batch_4/batch4-7.jpg';
import batch4_8 from '../assets/testimonials/College/Batch_4/batch4-8.jpg';
import batch4_9 from '../assets/testimonials/College/Batch_4/batch4-9.jpg';
import batch5_1 from '../assets/testimonials/College/Batch_5/batch5-1.jpg';
import batch5_2 from '../assets/testimonials/College/Batch_5/batch5-2.jpg';
import batch5_3 from '../assets/testimonials/College/Batch_5/batch5-3.jpg';
import batch6_1 from '../assets/testimonials/College/Batch_6/batch6-1.jpg';
import batch6_2 from '../assets/testimonials/College/Batch_6/batch6-2.jpg';
import batch6_3 from '../assets/testimonials/College/Batch_6/batch6-3.jpg';
import batch6_4 from '../assets/testimonials/College/Batch_6/batch6-4.jpg';
import batch6_5 from '../assets/testimonials/College/Batch_6/batch6-5.jpg';
import batch6_6 from '../assets/testimonials/College/Batch_6/batch6-6.jpg';
import batch6_7 from '../assets/testimonials/College/Batch_6/batch6-7.jpg';
import batch6_8 from '../assets/testimonials/College/Batch_6/batch6-8.jpg';
import batch6_9 from '../assets/testimonials/College/Batch_6/batch6-9.jpg'; 
import batch6_10 from '../assets/testimonials/College/Batch_6/batch6-10.jpg';
import batch6_11 from '../assets/testimonials/College/Batch_6/batch6-11.jpg';
import batch6_12 from '../assets/testimonials/College/Batch_6/batch6-12.jpg';
import batch6_13 from '../assets/testimonials/College/Batch_6/batch6-13.jpg';
import batch6_14 from '../assets/testimonials/College/Batch_6/batch6-14.jpg';
import batch6_15 from '../assets/testimonials/College/Batch_6/batch6-15.jpg';
import batch6_16 from '../assets/testimonials/College/Batch_6/batch6-16.jpg';
import batch7_1 from '../assets/testimonials/College/Batch_7/batch7-1.jpg';
import batch7_2 from '../assets/testimonials/College/Batch_7/batch7-2.jpg';
import batch7_3 from '../assets/testimonials/College/Batch_7/batch7-3.jpg';
import batch7_4 from '../assets/testimonials/College/Batch_7/batch7-4.jpg';
import batch8_1 from '../assets/testimonials/College/Batch_8/batch8-1.jpg';
import batch8_2 from '../assets/testimonials/College/Batch_8/batch8-2.jpg';
import batch8_3 from '../assets/testimonials/College/Batch_8/batch8-3.jpg';
import batch8_4 from '../assets/testimonials/College/Batch_8/batch8-4.jpg';
import batch8_5 from '../assets/testimonials/College/Batch_8/batch8-5.jpg';
import batch8_6 from '../assets/testimonials/College/Batch_8/batch8-6.jpg';
import batch9_1 from '../assets/testimonials/College/Batch_9/batch9-1.jpg';
import batch9_2 from '../assets/testimonials/College/Batch_9/batch9-2.jpg';
import batch9_3 from '../assets/testimonials/College/Batch_9/batch9-3.jpg';
import batch9_4 from '../assets/testimonials/College/Batch_9/batch9-4.jpg';
import batch10_1 from '../assets/testimonials/College/Batch_10/batch10-1.jpg';
import batch10_2 from '../assets/testimonials/College/Batch_10/batch10-2.jpg';
import batch10_3 from '../assets/testimonials/College/Batch_10/batch10-3.jpg';
import batch10_4 from '../assets/testimonials/College/Batch_10/batch10-4.jpg';
import batch11_1 from '../assets/testimonials/College/Batch_11/batch11-1.jpg';
import batch11_2 from '../assets/testimonials/College/Batch_11/batch11-2.jpg';
import batch11_3 from '../assets/testimonials/College/Batch_11/batch11-3.jpg';
import batch11_4 from '../assets/testimonials/College/Batch_11/batch11-4.jpg';
import batch11_5 from '../assets/testimonials/College/Batch_11/batch11-5.jpg';
import batch11_6 from '../assets/testimonials/College/Batch_11/batch11-6.jpg';
import batch12_1 from '../assets/testimonials/College/Batch_12/batch12-1.jpg';
import batch12_2 from '../assets/testimonials/College/Batch_12/batch12-2.jpg';
import batch12_3 from '../assets/testimonials/College/Batch_12/batch12-3.jpg';
import batch12_4 from '../assets/testimonials/College/Batch_12/batch12-4.jpg';
import batch12_5 from '../assets/testimonials/College/Batch_12/batch12-5.jpg';
import batch12_6 from '../assets/testimonials/College/Batch_12/batch12-6.jpg';
import batch12_7 from '../assets/testimonials/College/Batch_12/batch12-7.jpg';
import batch12_8 from '../assets/testimonials/College/Batch_12/batch12-8.jpg';
import batch12_9 from '../assets/testimonials/College/Batch_12/batch12-9.jpg';
import batch12_10 from '../assets/testimonials/College/Batch_12/batch12-10.jpg';

//When adding for senior highschool, do it here.
import hs_batch1_1 from '../assets/testimonials/High School/Batch_1/1.jpg';
import hs_batch1_2 from '../assets/testimonials/High School/Batch_1/2.jpg';
import hs_batch1_3 from '../assets/testimonials/High School/Batch_1/3.jpg';
import hs_batch1_4 from '../assets/testimonials/High School/Batch_1/4.jpg';
import hs_batch1_5 from '../assets/testimonials/High School/Batch_1/5.jpg';
import hs_batch1_6 from '../assets/testimonials/High School/Batch_1/6.jpg';
import hs_batch1_7 from '../assets/testimonials/High School/Batch_1/7.jpg';
import hs_batch1_8 from '../assets/testimonials/High School/Batch_1/8.jpg';
import hs_batch1_9 from '../assets/testimonials/High School/Batch_1/9.jpg';
import hs_batch1_10 from '../assets/testimonials/High School/Batch_1/10.jpg';
import hs_batch1_11 from '../assets/testimonials/High School/Batch_1/11.jpg';
import hs_batch1_12 from '../assets/testimonials/High School/Batch_1/12.jpg';
import hs_batch2_1 from '../assets/testimonials/High School/Batch_2/1.jpg';
import hs_batch2_2 from '../assets/testimonials/High School/Batch_2/2.jpg';
import hs_batch2_3 from '../assets/testimonials/High School/Batch_2/3.jpg';
import hs_batch2_4 from '../assets/testimonials/High School/Batch_2/4.jpg';
import hs_batch2_5 from '../assets/testimonials/High School/Batch_2/5.jpg';
import hs_batch2_6 from '../assets/testimonials/High School/Batch_2/6.jpg';
import hs_batch2_7 from '../assets/testimonials/High School/Batch_2/7.jpg';
import hs_batch2_8 from '../assets/testimonials/High School/Batch_2/8.jpg';
import hs_batch2_9 from '../assets/testimonials/High School/Batch_2/9.jpg';

import '../styles/CustomStyles/Testimonials.css';
import { Button } from 'bootstrap';

const initialTestimonialsData = [

//High School
  { id: 500, hs_batch: 1, image: hs_batch1_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 501, hs_batch: 1, image: hs_batch1_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 502, hs_batch: 1, image: hs_batch1_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 503, hs_batch: 1, image: hs_batch1_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 504, hs_batch: 1, image: hs_batch1_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 505, hs_batch: 1, image: hs_batch1_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 506, hs_batch: 1, image: hs_batch1_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 507, hs_batch: 1, image: hs_batch1_8, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 508, hs_batch: 1, image: hs_batch1_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 509, hs_batch: 1, image: hs_batch1_10, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 510, hs_batch: 1, image: hs_batch1_11, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 1' },
 // { id: 511, hs_batch: 1, image: hs_batch1_12, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment#HS_Batch2Interns', category: 'HS_Batch 1' },
  { id: 512, hs_batch: 2, image: hs_batch2_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch1Interns', category: 'HS_Batch 2' },
  { id: 513, hs_batch: 2, image: hs_batch2_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 514, hs_batch: 2, image: hs_batch2_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 515, hs_batch: 2, image: hs_batch2_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 516, hs_batch: 2, image: hs_batch2_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 517, hs_batch: 2, image: hs_batch2_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 518, hs_batch: 2, image: hs_batch2_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 519, hs_batch: 2, image: hs_batch2_8, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },
  { id: 520, hs_batch: 2, image: hs_batch2_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #HS_Batch2Interns', category: 'HS_Batch 2' },


  //College
  { id: 89, batch: 12, image: batch12_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 88, batch: 12, image: batch12_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 87, batch: 12, image: batch12_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 86, batch: 12, image: batch12_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 85, batch: 12, image: batch12_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 84, batch: 12, image: batch12_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 83, batch: 12, image: batch12_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 82, batch: 12, image: batch12_8, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 81, batch: 12, image: batch12_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 80, batch: 12, image: batch12_10, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch12Interns', category: 'Batch 12' },
  { id: 78, batch: 11, image: batch11_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch11Interns', category: 'Batch 11' },
  { id: 76, batch: 11, image: batch11_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch11Interns', category: 'Batch 11' },
  { id: 75, batch: 11, image: batch11_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch11Interns', category: 'Batch 11' },
  { id: 77, batch: 11, image: batch11_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch11Interns', category: 'Batch 11' },
  { id: 74, batch: 11, image: batch11_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch11Interns', category: 'Batch 11' },
  { id: 79, batch: 11, image: batch11_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch11Interns', category: 'Batch 11' },  
  { id: 70, batch: 10, image: batch10_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch10Interns', category: 'Batch 10' },
  { id: 71, batch: 10, image: batch10_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch10Interns', category: 'Batch 10' },
  { id: 72, batch: 10, image: batch10_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch10Interns', category: 'Batch 10' },
  { id: 73, batch: 10, image: batch10_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch10Interns', category: 'Batch 10' },
  { id: 66, batch: 9, image: batch9_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch9Interns', category: 'Batch 9' },
  { id: 67, batch: 9, image: batch9_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch9Interns', category: 'Batch 9' },
  { id: 68, batch: 9, image: batch9_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch9Interns', category: 'Batch 9' },
  { id: 69, batch: 9, image: batch9_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch9Interns', category: 'Batch 9' },
  { id: 1, batch: 8, image: batch8_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch8Interns', category: 'Batch 8' },
  { id: 2, batch: 8, image: batch8_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch8Interns', category: 'Batch 8' },
  { id: 3, batch: 8, image: batch8_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch8Interns', category: 'Batch 8' },
  { id: 4, batch: 8, image: batch8_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch8Interns', category: 'Batch 8' },
  { id: 5, batch: 8, image: batch8_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch8Interns', category: 'Batch 8' },
  { id: 6, batch: 8, image: batch8_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch8Interns', category: 'Batch 8' },
  { id: 7, batch: 7, image: batch7_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch7Interns', category: 'Batch 7' },
  { id: 8, batch: 7, image: batch7_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch7Interns', category: 'Batch 7' },
  { id: 9, batch: 7, image: batch7_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch7Interns', category: 'Batch 7' },
  { id: 10, batch: 7, image: batch7_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch7Interns', category: 'Batch 7' },
  { id: 11, batch: 6, image: batch6_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 12, batch: 6, image: batch6_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 13, batch: 6, image: batch6_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 14, batch: 6, image: batch6_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 15, batch: 6, image: batch6_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 16, batch: 6, image: batch6_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 17, batch: 6, image: batch6_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 18, batch: 6, image: batch6_8, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 19, batch: 6, image: batch6_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 20, batch: 6, image: batch6_10, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 21, batch: 6, image: batch6_11, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 22, batch: 6, image: batch6_12, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 23, batch: 6, image: batch6_13, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 24, batch: 6, image: batch6_14, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 25, batch: 6, image: batch6_15, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch6Interns', category: 'Batch 6' },
  { id: 26, batch: 5, image: batch5_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 5' },
  { id: 27, batch: 5, image: batch5_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 5' },
  { id: 28, batch: 5, image: batch5_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 5' },
  { id: 29, batch: 4, image: batch4_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 30, batch: 4, image: batch4_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 31, batch: 4, image: batch4_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 32, batch: 4, image: batch4_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 33, batch: 4, image: batch4_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 34, batch: 4, image: batch4_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 35, batch: 4, image: batch4_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 36, batch: 4, image: batch4_8, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 37, batch: 4, image: batch4_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch4Interns', category: 'Batch 4' },
  { id: 38, batch: 3, image: batch3_11, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 39, batch: 3, image: batch3_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 40, batch: 3, image: batch3_10, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 41, batch: 3, image: batch3_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 42, batch: 3, image: batch3_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 43, batch: 3, image: batch3_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 44, batch: 3, image: batch3_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 45, batch: 3, image: batch3_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 46, batch: 3, image: batch3_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 47, batch: 3, image: batch3_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch3Interns', category: 'Batch 3' },
  { id: 48, batch: 2, image: batch2_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 49, batch: 2, image: batch2_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 50, batch: 2, image: batch2_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 51, batch: 2, image: batch2_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 52, batch: 2, image: batch2_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 53, batch: 2, image: batch2_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 54, batch: 2, image: batch2_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 55, batch: 2, image: batch2_8, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 56, batch: 2, image: batch2_9, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 57, batch: 2, image: batch2_10, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 58, batch: 2, image: batch2_11, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch2Interns', category: 'Batch 2' },
  { id: 59, batch: 1, image: batch1_7, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
  { id: 60, batch: 1, image: batch1_6, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
  { id: 61, batch: 1, image: batch1_1, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
  { id: 62, batch: 1, image: batch1_5, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
  { id: 63, batch: 1, image: batch1_2, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
  { id: 64, batch: 1, image: batch1_3, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
  { id: 65, batch: 1, image: batch1_4, title: '#ModuleZeroProgram #Internship #LearningwithMYT #CareerDevelopment #Batch1Interns', category: 'Batch 1' },
];

function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [collection, setCollection] = useState([]);
  const [activeFilter, setActiveFilter] = useState(12);
  const [selectedCategory, setSelectedCategory] = useState("college"); // Default to college
  const [currentPage, setCurrentPage] = useState(0);
  const [batchesPerPage, setBatchesPerPage] = useState(10);

  useEffect(() => {
    const updateBatchesPerPage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setBatchesPerPage(2);
      } else if (screenWidth < 900) {
        setBatchesPerPage(3);
      } else if (screenWidth < 1200) {
        setBatchesPerPage(5);
      } else {
        setBatchesPerPage(10);
      }
    };

    updateBatchesPerPage();
    window.addEventListener('resize', updateBatchesPerPage);
    return () => window.removeEventListener('resize', updateBatchesPerPage);
  }, []);

  useEffect(() => {
    // Set initial data based on selected category
    if (selectedCategory === "college") {
      const batchCollection = [...new Set(initialTestimonialsData
        .filter(item => item.batch)
        .map(item => item.batch))];
      setCollection(batchCollection.sort((a, b) => b - a)); // Sort in descending order
      setActiveFilter(12);
      
      const batch12Data = initialTestimonialsData.filter(item => item.batch === 12);
      setTestimonialsData(batch12Data);
    } else {
      const hsBatchCollection = [...new Set(initialTestimonialsData
        .filter(item => item.hs_batch)
        .map(item => item.hs_batch))];
      setCollection(hsBatchCollection.sort((a, b) => b - a)); // Sort in descending order
      setActiveFilter(2);
      
      const hsBatch2Data = initialTestimonialsData.filter(item => item.hs_batch === 2);
      setTestimonialsData(hsBatch2Data);
    }
  }, [selectedCategory]);

  const filterTestimonials = (batchNumber) => {
    setActiveFilter(batchNumber);
    const filtered = selectedCategory === "college" 
      ? initialTestimonialsData.filter(item => item.batch === batchNumber)
      : initialTestimonialsData.filter(item => item.hs_batch === batchNumber);
    setTestimonialsData(filtered.map(item => ({ ...item, loaded: false })));
  };

  const switchCategory = (category) => {
    setSelectedCategory(category);
    setCurrentPage(0); // Reset to first page when switching categories
  };

  const handlePageChange = (direction) => {
    if (direction === 'next' && (currentPage + 1) * batchesPerPage < collection.length) {
      setCurrentPage(curr => curr + 1);
    } else if (direction === 'prev' && currentPage > 0) {
      setCurrentPage(curr => curr - 1);
    }
  };

  return (
    <>
      <header className="section-header pb-5 pt-5 text-center">
        <h2>Testimonials</h2>
      </header>

      <div className="container mb-4" data-aos="fade-up">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <button
              className={`toggle-button ${selectedCategory === "college" ? "active" : ""}`}
              onClick={() => switchCategory("college")}
            >
              College
            </button>
            <button
              className={`toggle-button ${selectedCategory === "highschool" ? "active" : ""}`}
              onClick={() => switchCategory("highschool")}
            >
              High School
            </button>
          </div>
        </div>
      </div>

      <section id="portfolio" className="portfolio">
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
          {currentPage > 0 && (
          <button 
            className="batch-nav-btn"
            onClick={() => handlePageChange('prev')}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
        )}
          
          <div className="portfolio-filters-container">
            <ul id="portfolio-flters">
              {collection
                .slice(currentPage * batchesPerPage, (currentPage + 1) * batchesPerPage)
                .map(batch => (
                  <li
                    key={batch}
                    onClick={() => filterTestimonials(batch)}
                    className={activeFilter === batch ? 'filter-active' : ''}
                  >
                    Batch {batch}
                  </li>
                ))}
            </ul>
          </div>
          
          {(currentPage + 1) * batchesPerPage < collection.length && (
            <button 
              className="batch-nav-btn"
              onClick={() => handlePageChange('next')}
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          )}
        </div>
          </div>
        </div>
      </section>
      <div className="container portfolio">
        <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
          {testimonialsData.map((item, index) => (
            <div key={item.id} className={`col-lg-4 col-md-6 portfolio-item filter-${item.batch}`}>
              <a href={item.image} title={item.title} data-gallery="portfolioGallery" className="portfokio-lightbox">
                <div className="portfolio-wrap">
                  <img
                    src={item.image}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <br /><br />
    </>
  );
}

export default Testimonials;
