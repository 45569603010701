import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import "../styles/CustomStyles/AboutUs.css"
import new_logo from '../assets/module-zero-logo.png';
import act from '../assets/universities/act.png';
import cit from '../assets/universities/cit.png';
import ctu from '../assets/universities/ctu.png';
import uc from '../assets/universities/uc.png';
import up from '../assets/universities/up.png';
import usc from '../assets/universities/usc.png';
import uv from '../assets/universities/uv.png';
import nnsc from '../assets/universities/nnsc.png';
import usjr from '../assets/universities/USJR-Logo.jpg';
import stc from '../assets/universities/stc.png';
import benthel from '../assets/universities/Benthel.png';
import pn from '../assets/universities/PN.png';

import expect1 from '../assets/expect1.png';
import expect2 from '../assets/expect2.png';
import expect3 from '../assets/expect3.png';
import expect4 from '../assets/expect4.png';

import { ReactComponent as One } from '../assets/1.svg';
import { ReactComponent as Two } from '../assets/2.svg';
import { ReactComponent as Three } from '../assets/3.svg';
import { ReactComponent as Four } from '../assets/4.svg';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function AboutUs() {
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [actLoaded, setActLoaded] = useState(false);
  const [citLoaded, setCitLoaded] = useState(false);
  const [ctuLoaded, setCtuLoaded] = useState(false);
  const [ucLoaded, setUcLoaded] = useState(false);
  const [nnscLoaded, setNnscLoaded] = useState(false);
  const [upLoaded, setUpLoaded] = useState(false);
  const [uscLoaded, setUscLoaded] = useState(false);
  const [usjrLoaded, setUsjrLoaded] = useState(false);
  const [stcLoaded, setStcLoaded] = useState(false);
  const [benthelLoaded, setBenthelLoaded] = useState(false);
  const [pnLoaded, setPnLoaded] = useState(false);
  const [expect1Loaded, setExpect1Loaded] = useState(false);
  const [expect2Loaded, setExpect2Loaded] = useState(false);
  const [expect3Loaded, setExpect3Loaded] = useState(false);
  const [expect4Loaded, setExpect4Loaded] = useState(false);

  const handleLogoLoad = () => {
    setLogoLoaded(true);
  };

  const handleImageLoad = (setImageLoaded) => {
    setImageLoaded(true);
  };

  return (
    <>
      <header className="section-header pb-5 pt-5 text-center">
        <h2>About Module Zero</h2>
      </header>
      <div className='container-fluid overflow'>
        <div className="container">
          <div className="justify-content-center">
            <div className="logo-box text-center">
              {!logoLoaded && <Skeleton animation="wave" variant="rectangular" width={550} height={150} style={{ borderRadius: 10 }} />}
              <img
                className={`new-logo mx-auto ${logoLoaded ? 'loaded' : 'not-loaded'}`}
                src={new_logo}
                onLoad={handleLogoLoad}
                alt="Module Zero Logo"
                style={{ display: logoLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-11 col-md-9 about-text text-start text-md-center'>
            <p>The internship program aims to train young professionals in the field of software technology where they will be trained and taught in the different pathways of the program such as Design, Project Management, Software Development, and Quality Assurance.</p>
            <p>In Module Zero, we believe that everyone has a place in tech as our program has become a home to a variety of aspiring professionals all around the Philippines and coming from different specialties. And also, our interns are also from non-STEM programs.</p>
            <p>We're more than ecstatic to see the interns dive deeper than ever in the industry in the next batches to come. Aja, interns!</p>
          </div>
        </div>
      </div>

      <div className='container-fluid py-3 mt-5' style={{backgroundColor: "#4fc0e8"}}>
        <div className='row'>
          <div className='col text-center company-num'>
            Our Partner Institutions
          </div>
        </div>
      </div>

      <div className='container mt-5'>
        <div className='row card-text gy-5 pb-5 justify-content-center'>
          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!actLoaded && <Skeleton animation="wave" variant="rectangular" width={180} height={120} style={{ objectFit: 'contain', borderRadius: 10, marginLeft: '40px' , marginTop: '39px' }} />}
              <img
                className={`${actLoaded ? 'loaded' : 'not-loaded'}`}
                src={act}
                onLoad={() => handleImageLoad(setActLoaded)}
                style={{ display: actLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!citLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${citLoaded ? 'loaded' : 'not-loaded'}`}
                src={cit}
                onLoad={() => handleImageLoad(setCitLoaded)}
                style={{ display: citLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!ctuLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${ctuLoaded ? 'loaded' : 'not-loaded'}`}
                src={ctu}
                onLoad={() => handleImageLoad(setCtuLoaded)}
                style={{ display: ctuLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!ucLoaded && <Skeleton animation="wave" variant="rectangular" width={180} height={120} style={{ objectFit: 'contain', borderRadius: 10, marginLeft: '40px' , marginTop: '39px' }} />}
              <img
                className={`${ucLoaded ? 'loaded' : 'not-loaded'}`}
                src={uc}
                onLoad={() => handleImageLoad(setUcLoaded)}
                style={{ display: ucLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!nnscLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${nnscLoaded ? 'loaded' : 'not-loaded'}`}
                src={nnsc}
                onLoad={() => handleImageLoad(setNnscLoaded)}
                style={{ display: nnscLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!upLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${upLoaded ? 'loaded' : 'not-loaded'}`}
                src={up}
                onLoad={() => handleImageLoad(setUpLoaded)}
                style={{ display: upLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!uscLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${uscLoaded ? 'loaded' : 'not-loaded'}`}
                src={usc}
                onLoad={() => handleImageLoad(setUscLoaded)}
                style={{ display: uscLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!usjrLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${usjrLoaded ? 'loaded' : 'not-loaded'}`}
                src={usjr}
                onLoad={() => handleImageLoad(setUsjrLoaded)}
                style={{ display: usjrLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!stcLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${stcLoaded? 'loaded' : 'not-loaded'}`}
                src={stc}
                onLoad={() => handleImageLoad(setStcLoaded)}
                style={{ display: stcLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!benthelLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${benthelLoaded ? 'loaded' : 'not-loaded'}`}
                src={benthel}
                onLoad={() => handleImageLoad(setBenthelLoaded)}
                style={{ display: benthelLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

          <div className='col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center'>
              {!pnLoaded && <Skeleton animation="wave" variant="circular" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px' }} />}
              <img
                className={`${pnLoaded ? 'loaded' : 'not-loaded'}`}
                src={pn}
                onLoad={() => handleImageLoad(setPnLoaded)}
                style={{ display: pnLoaded ? 'block' : 'none' }}
              />
            </div>
          </div>

        </div>
      </div>

      <div>
        <div className='container-fluid py-3 mt-5'>
          <div className='row'>
            <div className='col text-center about-title'>
            MYT ROADMAP
            </div>
          </div>
        </div>
        <VerticalTimeline lineColor={'#3e3e40'}> 
          <VerticalTimelineElement
            className="vertical-timeline-element"
            //date = "Data and Business Analytics"
            contentStyle={{ background: '#5CC8E4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '10px solid  #5CC8E4' }}
            iconStyle={{ background: '#5CC8E4', color: '#fff' }}
            icon={<One />}
          >
            <h3 className="vertical-timeline-element-title">Data and Business Analytics</h3>
            <p>
              MYT shall delve  into the domain of Data and Business Analytics, harnessing the power of data-driven insights to help organizations make informed decisions, optimize operations, and unlock new opportunities for growth, thus enabling them to stay ahead in today's dynamic business landscape.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            contentStyle={{ background: '#5CC8E4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '10px solid  #5CC8E4' }}
            iconStyle={{ background: '#5CC8E4', color: '#fff' }}
           icon={<Two />}
          >
            <h3 className="vertical-timeline-element-title">Technical Sales</h3>
            <p>
              MYT shall venture into the field of Technical Sales, leveraging our expertise to provide comprehensive solutions to customers by bridging the gap between their technical requirements and our innovative products and services, ultimately driving growth and customer satisfaction.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            contentStyle={{ background: '#5CC8E4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '10px solid  #5CC8E4' }}
            iconStyle={{ background: '#5CC8E4', color: '#fff' }}
            icon={<Three />}
          >
            <h3 className="vertical-timeline-element-title">AI Integration</h3>
            <p>
              MYT shall integrate modern technologies including Artificial Intelligence in creating smarter and more effective solutions to address business needs and better accommodate client operations.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            contentStyle={{ background: '#5CC8E4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '10px solid  #5CC8E4' }}
            iconStyle={{ background: '#5CC8E4', color: '#fff' }}
            icon={<Four />}
          >
            <h3 className="vertical-timeline-element-title">Proactive Community Partners</h3>
            <p>
              MYT shall engage in various partnerships in the academe, social, and industry level organizations and entities strengthening its vision to provide aid to those in need and creating skilled professionals in technology.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>

      <div className='container-fluid py-3 mt-5'>
        <div className='row'>
          <div className='col text-center about-title'>
          EXPECTATIONS
          </div>
        </div>
      </div>

      <div className='container py-3'>
        <div className='row card-text gy-5 pb-5 justify-content-center'>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3' data-aos="fade-up" data-aos-delay="50">
            <div className='card mx-auto text-center' style={{background: 'radial-gradient(circle, #fff1de, #DCF2FA)'}}>
              {!expect3Loaded && <Skeleton animation="wave" variant="rounded" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px', marginBottom: '20px' }} />}
              <img
                className={`mb-3 ${expect3Loaded ? 'loaded' : 'not-loaded'}`}
                src={expect3}
                onLoad={() => handleImageLoad(setExpect3Loaded)}
                style={{ display: expect3Loaded ? 'block' : 'none' }}
              />
              <h1>Feedback and Assessment</h1>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3' data-aos="fade-up" data-aos-delay="100">
            <div className='card mx-auto text-center' style={{background: 'radial-gradient(circle, #fff1de, #DCF2FA)'}}>
            {!expect1Loaded && <Skeleton animation="wave" variant="rounded" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px', marginBottom: '20px' }} />}
              <img
                className={`mb-3 ${expect1Loaded ? 'loaded' : 'not-loaded'}`}
                src={expect1}
                onLoad={() => handleImageLoad(setExpect1Loaded)}
                style={{ display: expect1Loaded ? 'block' : 'none' }}
              />
              <h1>Academic Collaboration</h1>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3' data-aos="fade-up" data-aos-delay="150">
            <div className='card mx-auto text-center' style={{background: 'radial-gradient(circle, #fff1de, #DCF2FA)'}}>
              {!expect2Loaded && <Skeleton animation="wave" variant="rounded" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px', marginBottom: '20px' }} />}
              <img
                className={`mb-3 ${expect2Loaded ? 'loaded' : 'not-loaded'}`}
                src={expect2}
                onLoad={() => handleImageLoad(setExpect2Loaded)}
                style={{ display: expect2Loaded ? 'block' : 'none' }}
              />
              <h1>Proactive Communication</h1>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3' data-aos="fade-up" data-aos-delay="150">
            <div className='card mx-auto text-center' style={{background: 'radial-gradient(circle, #fff1de, #DCF2FA)'}}>
              {!expect4Loaded && <Skeleton animation="wave" variant="rounded" width={180} height={180} style={{ objectFit: 'contain', marginLeft: '40px', marginBottom: '20px' }} />}
              <img
                className={`mb-3 ${expect4Loaded ? 'loaded' : 'not-loaded'}`}
                src={expect4}
                onLoad={() => handleImageLoad(setExpect4Loaded)}
                style={{ display: expect4Loaded ? 'block' : 'none' }}
              />
              <h1>Work-life Balance</h1>
            </div>
          </div>

          {/* <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='card mx-auto text-center' style={{background: 'radial-gradient(circle, #fff1de, #F1CB52)'}}>
              <img className='mb-3' src={expect2}/>
              <h1>Proactive Communication</h1>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default AboutUs;