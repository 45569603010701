import React, { useState } from 'react';
import '../styles/CustomStyles/Navbar.css';
import { NavLink } from 'react-router-dom';
import mytlogo from '../assets/MYT_Module_Zero.png';

function Navbar() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    const closeNav = () => {
        setIsNavCollapsed(true);
    };

    return (
        <div className='navbar navbar-expand-lg navbar-light justify-content-between navtext sticky-top' style={{backgroundColor: "#f4f4f4"}}>
            <div className='d-none d-md-block navbar-brand'> 
                <img className='appLogo' src={mytlogo} alt="Module Zero Logo"/>
            </div>
            <div className='d-md-none navbar-brand'> 
                <img className='appLogo' src={mytlogo} alt="Module Zero Logo"/>
            </div>
            <button 
                type="button" 
                className="navbar-toggler me-2"
                onClick={handleNavCollapse}
                aria-expanded={!isNavCollapsed}
                aria-controls="mytNav" 
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id="mytNav">
                <div className='nav navbar-nav mt-2 mt-lg-0'>
                    <NavLink to="/" className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} onClick={closeNav}>Home</NavLink>
                    <NavLink to="/about" className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} onClick={closeNav}>About</NavLink>
                    <NavLink to="/testimonials" className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} onClick={closeNav}>Testimonials</NavLink>
                    <NavLink to="/stories" className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} onClick={closeNav}>Stories</NavLink>
                    <NavLink to="/apply" className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} onClick={closeNav}>Apply</NavLink>
                </div>
            </div>
        </div>
    );
}

export default Navbar;